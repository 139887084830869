import React, { useLayoutEffect, useState } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import PropTypes from 'prop-types'
import { EventsMain } from './index.style'
import Spacer from '@components/Spacer'
import { Heading2, TextBody } from '@components/TextStyles'
import Container from '@components/Container'
import Cta from '@components/Cta'
import Event from '@components/Event'
import { getFilteredEvents } from '@utils/utils'
import AnimateSlideIn from '@components/animation/AnimateSlideIn'

const Events = ({ title, text, event }) => {
  const { contentfulEvents, allContentfulEvent } = useStaticQuery(graphql`
    query {
      contentfulEvents {
        slug
      }
      allContentfulEvent(sort: { fields: startTime, order: DESC }) {
        nodes {
          ...ContentfulEvent
        }
      }
    }
  `)
  const [featuredEvent, setFeaturedEvent] = useState()

  useLayoutEffect(() => {
    if (event) {
      setFeaturedEvent(event)
    } else {
      const filteredEvents = getFilteredEvents(allContentfulEvent.nodes)

      if (filteredEvents.upcoming.length) {
        setFeaturedEvent(filteredEvents.upcoming[0])
      }
    }
  }, [event, allContentfulEvent.nodes])

  return (
    <EventsMain>
      <Spacer size={[56, 80]} />

      <Container>
        <AnimateSlideIn>
          <Heading2 secondary>{title}</Heading2>
        </AnimateSlideIn>

        <Spacer size={[16, 24]} />

        <AnimateSlideIn>
          <TextBody>{text}</TextBody>
        </AnimateSlideIn>

        {featuredEvent && (
          <>
            <Spacer size={[32, 48]} />

            <Event data={featuredEvent} />
          </>
        )}

        <Spacer size={[32, 48]} />

        <Cta to={contentfulEvents.slug}>View all</Cta>
      </Container>

      <Spacer size={[56, 88]} />
    </EventsMain>
  )
}

Events.propTypes = {
  title: PropTypes.string,
  text: PropTypes.string,
  event: PropTypes.object,
}

export default Events
