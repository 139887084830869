import React from 'react'
import PropTypes from 'prop-types'
import { EventContent, EventImage, EventMain } from './index.style'
import { Heading2, Heading4 } from '@components/TextStyles'
import Spacer from '@components/Spacer'
import RichText from '@components/RichText'
import { GatsbyImage } from 'gatsby-plugin-image'
import LinkCta from '@components/LinkCta'
import { colors } from '@styles/vars/colors.style'
import AnimateSlideIn from '@components/animation/AnimateSlideIn'

const Event = ({ data, backgroundColor = colors.cream }) => {
  const {
    startDateFormatted,
    startTimeHour,
    startTimeMinutes,
    startTimeMeridiem,
    endDateFormatted,
    endTimeHour,
    endTimeMinutes,
    endTimeMeridiem,
  } = data
  const startTime = `${startTimeHour}${
    startTimeMinutes !== '00' ? `.${startTimeMinutes}` : ''
  }${!endDateFormatted ? startTimeMeridiem : ''}`
  const endTime = endDateFormatted
    ? ` - ${endTimeHour}${
        endTimeMinutes !== '00' ? `.${endTimeMinutes}` : ''
      }${endTimeMeridiem}`
    : ''
  const time = `${startTime}${endTime}`
  const hasImage = data.image ? true : false

  return (
    <AnimateSlideIn duration={0.3}>
      <EventMain hasImage={hasImage} backgroundColor={backgroundColor}>
        {data.image && (
          <EventImage>
            <GatsbyImage
              image={data.image.gatsbyImageData}
              alt={data.image.description}
            />
          </EventImage>
        )}

        <EventContent hasImage={hasImage}>
          {data.name && <Heading2 secondary>{data.name}</Heading2>}

          <Spacer size={[8, 16]} />

          <Heading4>
            {startDateFormatted}
            {endDateFormatted && startDateFormatted !== endDateFormatted
              ? ` - ${endDateFormatted}`
              : ''}
            {data.location ? ` - ${data.location}` : ''}
          </Heading4>

          <Spacer size={8} />

          <Heading4 secondary>{time}</Heading4>

          {data.description && (
            <>
              <Spacer size={20} />

              <RichText
                content={data.description}
                paragraphSize="small"
                buildOn={false}
              />
            </>
          )}

          {data.cta && (
            <>
              <Spacer size={[20, 32]} />

              <LinkCta cta={data.cta} />
            </>
          )}
        </EventContent>
      </EventMain>
    </AnimateSlideIn>
  )
}

Event.propTypes = {
  data: PropTypes.object,
  backgroundColor: PropTypes.string,
}

export default Event
