import styled from 'styled-components'
import { clamp } from '@styles/utils/conversion.style'
import { colors } from '@styles/vars/colors.style'
import { grid } from '@styles/vars/grid.style'

export const ServicesMain = styled.div`
  position: relative;
  text-align: center;
  background-color: ${colors.yellow};
`

export const ServicesGrid = styled.div`
  display: grid;
  ${clamp('grid-gap', grid.gap.min, grid.gap.max)}
  grid-template-columns: repeat(auto-fit, minmax(27.2rem, 1fr));
`

export const ServicesCard = styled.div`
  position: relative;
  ${clamp('padding-right', 24, 32)}
  ${clamp('padding-left', 24, 32)}
  padding-bottom: 3.2rem;
  text-align: left;
  background-color: ${colors.cream};
  border-radius: 1.6rem;
`

export const ServicesCardImage = styled.img`
  width: 16rem;
  ${clamp('margin-left', -24, -32)}
`
