import { colors } from '@styles/vars/colors.style'
import { TextBody } from '@styles/vars/textStyles.style'
import styled from 'styled-components'

export const VoicesMain = styled.div`
  text-align: center;
  background-color: ${colors.light};

  ${TextBody} {
    margin-left: auto;
    margin-right: auto;
  }
`
