import { easings } from '@styles/vars/easings.style'
import { font } from '@styles/vars/font.style'
import styled from 'styled-components'

export const CtaMain = styled.div`
  position: relative;
  display: inline-flex;
  font-size: ${props => (props.size === 'small' ? '1.6rem' : '2rem')};
  font-weight: ${font.primary.weight.bold};
  line-height: 1.6;
  text-decoration: none;

  ::before {
    content: '';
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    transform: ${props => (props.inView ? 'scaleX(1)' : 'scaleX(0)')};
    display: block;
    height: 0.2rem;
    background-color: ${props => props.fill};
    transition: transform 0.2s ${easings.inOut.default} 0.5s;
  }
`
