import React from 'react'
import { graphql } from 'gatsby'
import Seo from '../components/Seo'
import Footer from '../components/Footer'
import Hero from '@components/Home/Hero'
import About from '@components/Home/About'
import Events from '@components/Home/Events'
import Services from '@components/Home/Services'
import Voices from '@components/Home/Voices'

const IndexPage = ({ data }) => {
  const {
    heroText: { heroText },
    heroButton,
    heroImage,
    aboutTitle,
    aboutText,
    aboutButton,
    aboutImage,
    eventsTitle,
    eventsText: { eventsText },
    featuredEvent,
    ourServicesTitle,
    workshopsTitle,
    workshopsText: { workshopsText },
    youngPeopleTitle,
    youngPeopleText: { youngPeopleText },
    advocacyTitle,
    advocacyText: { advocacyText },
    servicesCta,
    youngPeoplesVoicesTitle,
    youngPeoplesVoicesText,
    youngPeoplesVoicesYouTubeVideoId,
    youngPeoplesVoicesCta,
  } = data.contentfulHome

  const services = [
    {
      title: workshopsTitle,
      text: workshopsText,
    },
    {
      title: youngPeopleTitle,
      text: youngPeopleText,
    },
    {
      title: advocacyTitle,
      text: advocacyText,
    },
  ]

  return (
    <>
      <Seo title="Home" />

      <Hero text={heroText} button={heroButton} image={heroImage} />

      <About
        title={aboutTitle}
        text={aboutText}
        button={aboutButton}
        image={aboutImage}
      />

      <Events title={eventsTitle} text={eventsText} event={featuredEvent} />

      <Services title={ourServicesTitle} items={services} cta={servicesCta} />

      <Voices
        title={youngPeoplesVoicesTitle}
        text={youngPeoplesVoicesText}
        videoId={youngPeoplesVoicesYouTubeVideoId}
        cta={youngPeoplesVoicesCta}
      />

      <Footer />
    </>
  )
}

export default IndexPage

export const pageQuery = graphql`
  query Home {
    contentfulHome {
      heroText {
        heroText
      }
      heroButton {
        ... on Node {
          ...ContentfulButton
        }
      }
      heroImage {
        ...Image
      }
      aboutTitle
      aboutText {
        raw
      }
      aboutImage {
        ...Image
      }
      aboutButton {
        ... on Node {
          ...ContentfulButton
        }
      }
      eventsTitle
      eventsText {
        eventsText
      }
      featuredEvent {
        ...ContentfulEvent
      }
      ourServicesTitle
      workshopsTitle
      workshopsText {
        workshopsText
      }
      youngPeopleTitle
      youngPeopleText {
        youngPeopleText
      }
      advocacyTitle
      advocacyText {
        advocacyText
      }
      servicesCta {
        ... on Node {
          ...ContentfulButton
        }
      }
      youngPeoplesVoicesTitle
      youngPeoplesVoicesText {
        raw
      }
      youngPeoplesVoicesYouTubeVideoId
      youngPeoplesVoicesCta {
        ... on Node {
          ...ContentfulButton
        }
      }
    }
  }
`
