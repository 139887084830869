import React from 'react'
import PropTypes from 'prop-types'
import { HeroImage, HeroMain } from './index.style'
import Container from '@components/Container'
import Grid from '@components/Grid'
import GridItem from '@components/GridItem'
import { Heading1 } from '@components/TextStyles'
import Spacer from '@components/Spacer'
import LinkButton from '@components/LinkButton'
import IconArrowRight from '@components/svgs/IconArrowRight'
import AnimateSlideIn from '@components/animation/AnimateSlideIn'
import { GatsbyImage } from 'gatsby-plugin-image'

const Hero = ({ text, button, image }) => (
  <HeroMain>
    <Container>
      <Grid alignItems="center">
        <GridItem tabletP={6}>
          <AnimateSlideIn>
            <Heading1 secondary>{text}</Heading1>
          </AnimateSlideIn>

          {button && (
            <>
              <Spacer size={[24, 40]} />

              <AnimateSlideIn>
                <LinkButton
                  button={button}
                  variant="secondary"
                  iconRight={<IconArrowRight />}
                />
              </AnimateSlideIn>
            </>
          )}
        </GridItem>

        <GridItem tabletP={6} tabletL={5}>
          <AnimateSlideIn delay={0.05}>
            <HeroImage>
              <GatsbyImage
                image={image.gatsbyImageData}
                alt={image.description}
              />
            </HeroImage>
          </AnimateSlideIn>
        </GridItem>
      </Grid>
    </Container>

    <Spacer size={[48, 80]} />
  </HeroMain>
)

Hero.propTypes = {
  text: PropTypes.string,
  button: PropTypes.object,
  image: PropTypes.object,
}

export default Hero
