import React from 'react'
import PropTypes from 'prop-types'
import { VideoMain } from './index.style'
import AnimateSlideIn from '@components/animation/AnimateSlideIn'

const Video = ({ id }) => {
  return (
    <AnimateSlideIn>
      <VideoMain>
        <iframe
          src={`https://www.youtube.com/embed/${id}?controls=0`}
          frameBorder="0"
          title="YouTube video player"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        />
      </VideoMain>
    </AnimateSlideIn>
  )
}

Video.propTypes = {
  id: PropTypes.string,
}

export default Video
