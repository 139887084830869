import React from 'react'
import PropTypes from 'prop-types'
import { AboutImage, AboutMain } from './index.style'
import Container from '@components/Container'
import Grid from '@components/Grid'
import GridItem from '@components/GridItem'
import { Heading2 } from '@components/TextStyles'
import Spacer from '@components/Spacer'
import RichText from '@components/RichText'
import LinkButton from '@components/LinkButton'
import IconArrowRight from '@components/svgs/IconArrowRight'
import Border from '@components/Border'
import AnimateSlideIn from '@components/animation/AnimateSlideIn'
import { GatsbyImage } from 'gatsby-plugin-image'

const About = ({ title, text, button, image }) => (
  <AboutMain>
    <Border />
    <Spacer size={[48, 80]} />

    <Container>
      <Grid alignItems="center">
        <GridItem tabletP={6} tabletPOrder={2} tabletPStart={7}>
          <AnimateSlideIn>
            <Heading2 secondary>{title}</Heading2>
          </AnimateSlideIn>

          <Spacer size={[16, 24]} />

          <RichText content={text} />

          <Spacer size={32} />

          <AnimateSlideIn>
            <LinkButton button={button} iconRight={<IconArrowRight />} />
          </AnimateSlideIn>
        </GridItem>

        <GridItem tabletP={5} tabletPOrder={1}>
          <AnimateSlideIn delay={0.05}>
            <AboutImage>
              <GatsbyImage
                image={image.gatsbyImageData}
                alt={image.description}
              />
            </AboutImage>
          </AnimateSlideIn>
        </GridItem>
      </Grid>
    </Container>

    <Spacer size={[36, 80]} />
    <Border align="bottom" />
  </AboutMain>
)

About.propTypes = {
  title: PropTypes.string,
  text: PropTypes.object,
  button: PropTypes.object,
  image: PropTypes.object,
}

export default About
