import React from 'react'
import PropTypes from 'prop-types'
import { VoicesMain } from './index.style'
import Spacer from '@components/Spacer'
import { Heading2 } from '@components/TextStyles'
import RichText from '@components/RichText'
import LinkCta from '@components/LinkCta'
import Container from '@components/Container'
import Video from '@components/Video'
import AnimateSlideIn from '@components/animation/AnimateSlideIn'

const Voices = ({ title, text, videoId, cta }) => {
  return (
    <VoicesMain>
      <Spacer size={[48, 80]} />

      <Container>
        <AnimateSlideIn>
          <Heading2 secondary>{title}</Heading2>
        </AnimateSlideIn>

        <Spacer size={[16, 24]} />

        <RichText content={text} maxWidth={39.6} />

        <Spacer size={[24, 48]} />

        <Video id={videoId} />

        <Spacer size={[24, 40]} />

        <LinkCta cta={cta} />
      </Container>

      <Spacer size={[56, 88]} />
    </VoicesMain>
  )
}

Voices.propTypes = {
  title: PropTypes.string,
  text: PropTypes.object,
  videoId: PropTypes.string,
  cta: PropTypes.object,
}

export default Voices
