import styled from 'styled-components'

export const VideoMain = styled.div`
  position: relative;
  display: inline-flex;
  width: 100%;
  max-width: 84.2rem;
  aspect-ratio: 16 / 9;
  overflow: hidden;
  border-radius: 0.8rem;

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`
