import { colors } from '@styles/vars/colors.style'
import { mq } from '@styles/vars/media-queries.style'
import styled from 'styled-components'

export const AboutMain = styled.div`
  position: relative;
  color: ${colors.light};
  background-color: ${colors.dark};
`

export const AboutImage = styled.div`
  ${mq.tabletPMax} {
    max-width: 30rem;
    margin-top: 2.4rem;
    margin-left: auto;
    margin-right: auto;
  }

  ${mq.tabletP} {
    text-align: center;
  }
`
