import { clamp } from '@styles/utils/conversion.style'
import { mq } from '@styles/vars/media-queries.style'
import styled from 'styled-components'

export const EventMain = styled.div`
  max-width: ${props => (props.hasImage ? '95.1rem' : '58.3rem')};
  margin-left: auto;
  margin-right: auto;
  ${clamp('padding-right', 24, 40)}
  ${clamp('padding-left', 24, 40)}
  ${clamp('padding-top', 24, 40)}
  ${clamp('padding-bottom', 32, 40)}
  text-align: left;
  background-color: ${props => props.backgroundColor};
  border-radius: 1.6rem;

  ${mq.tabletP} {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
  }
`

export const EventImage = styled.div`
  max-width: 32.3rem;
  margin-left: auto;
  margin-right: auto;
  ${clamp('margin-bottom', 24, 40)}
  overflow: hidden;
  border-radius: 0.8rem;

  ${mq.tabletP} {
    margin-bottom: 0;
  }
`

export const EventContent = styled.div`
  ${mq.tabletP} {
    flex: 1;
    padding-right: ${props => (props.hasImage ? '4.5rem' : undefined)};
  }
`
