import { clamp } from '@styles/utils/conversion.style'
import { breakpoints } from '@styles/vars/breakpoints.style'
import { colors } from '@styles/vars/colors.style'
import { mq } from '@styles/vars/media-queries.style'
import styled from 'styled-components'

export const HeroMain = styled.div`
  ${clamp('padding-top', 150, 170, breakpoints.mobile, breakpoints.tabletP)}
  background-color: ${colors.yellow};

  ${mq.tabletP} {
    ${clamp('padding-top', 150, 160, breakpoints.tabletP)}
  }
`

export const HeroImage = styled.div`
  ${mq.tabletPMax} {
    max-width: 40rem;
    margin-top: 2.4rem;
    margin-left: auto;
    margin-right: auto;
  }

  ${mq.tabletP} {
    text-align: center;
  }
`
