import React from 'react'
import PropTypes from 'prop-types'
import Cta from '@components/Cta'

const LinkCta = ({ cta, ...props }) => (
  <>
    {cta.page && (
      <Cta type="internalLink" to={cta.page.slug} {...props}>
        {cta.text}
      </Cta>
    )}

    {cta.url && (
      <Cta type="externalLink" href={cta.url} {...props}>
        {cta.text}
      </Cta>
    )}
  </>
)

LinkCta.propTypes = {
  cta: PropTypes.object,
}

export default LinkCta
