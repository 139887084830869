import React from 'react'
import PropTypes from 'prop-types'
import {
  ServicesCard,
  ServicesCardImage,
  ServicesGrid,
  ServicesMain,
} from './index.style'
import Spacer from '@components/Spacer'
import Container from '@components/Container'
import { Heading2, Heading3, TextBodySmall } from '@components/TextStyles'
import LinkCta from '@components/LinkCta'
import { colors } from '@styles/vars/colors.style'
import workshopsImage from './images/workshops.png'
import youngImage from './images/young.png'
import advocacyImage from './images/advocacy.png'
import Border from '@components/Border'
import AnimateSlideIn from '@components/animation/AnimateSlideIn'

const Services = ({ title, items, cta }) => {
  const images = [
    { alt: 'A hand offering up a feathered quill', src: youngImage },
    { alt: 'A hand offering up a smiling heart', src: workshopsImage },
    { alt: 'A hand holding a placard that reads "Yes"', src: advocacyImage },
  ]

  return (
    <ServicesMain>
      <Border color={colors.yellow} />

      <Spacer size={[42, 80]} />

      <Container>
        <AnimateSlideIn>
          <Heading2 secondary>{title}</Heading2>
        </AnimateSlideIn>

        <Spacer size={[40, 48]} />

        <ServicesGrid>
          {React.Children.toArray(
            items.map((service, serviceIndex) => (
              <AnimateSlideIn duration={0.3} delay={serviceIndex * 0.1}>
                <ServicesCard>
                  <ServicesCardImage
                    src={images[serviceIndex].src}
                    alt={images[serviceIndex].alt}
                  />

                  <Spacer size={8} />

                  <Heading3 secondary>{service.title}</Heading3>

                  <Spacer size={24} />

                  <TextBodySmall>{service.text}</TextBodySmall>
                </ServicesCard>
              </AnimateSlideIn>
            ))
          )}
        </ServicesGrid>

        <Spacer size={[38, 48]} />
      </Container>

      <LinkCta cta={cta} fill={colors.light} size="small" />

      <Spacer size={[48, 60]} />

      <Border align="bottom" color={colors.yellow} />
    </ServicesMain>
  )
}

Services.propTypes = {
  title: PropTypes.string,
  items: PropTypes.arrayOf(PropTypes.object),
  cta: PropTypes.object,
}

export default Services
