import React from 'react'
import PropTypes from 'prop-types'
import { CtaMain } from './index.style'
import { useInView } from 'react-intersection-observer'
import PageTransitionLink from '@components/PageTransitionLink'
import AnimateSlideIn from '@components/animation/AnimateSlideIn'
import { useStore } from '@Store/'
import { colors } from '@styles/vars/colors.style'
import { animation } from '@styles/vars/animation.style'

const Cta = ({
  type = 'internalLink',
  href,
  to,
  size = 'regular',
  buildOn = true,
  fill = colors.yellow,
  children,
}) => {
  const [ref, inView] = useInView({
    triggerOnce: true,
    rootMargin: animation.rootMargin,
  })
  const [store] = useStore()
  const { showPageMask } = store

  const getContent = () =>
    buildOn ? (
      <AnimateSlideIn>
        {children} {'>'}
      </AnimateSlideIn>
    ) : (
      <>
        {children} {'>'}
      </>
    )

  return (
    <>
      {type === 'externalLink' && (
        <CtaMain
          ref={ref}
          as="a"
          href={href}
          inView={inView && !showPageMask}
          fill={fill}
          size={size}
        >
          {getContent()}
        </CtaMain>
      )}

      {type === 'internalLink' && (
        <PageTransitionLink to={to}>
          <CtaMain
            ref={ref}
            inView={inView && !showPageMask}
            fill={fill}
            size={size}
          >
            {getContent()}
          </CtaMain>
        </PageTransitionLink>
      )}
    </>
  )
}

Cta.propTypes = {
  type: PropTypes.oneOf(['internalLink', 'externalLink']),
  size: PropTypes.oneOf(['regular', 'small']),
  href: PropTypes.string,
  to: PropTypes.string,
  buildOn: PropTypes.string,
  fill: PropTypes.string,
  children: PropTypes.node,
}

export default Cta
