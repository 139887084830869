import React from 'react'
import PropTypes from 'prop-types'
import Button from '@components/Button'

const LinkButton = ({ button, ...props }) => (
  <>
    {button.page && (
      <Button type="internalLink" to={button.page.slug} {...props}>
        {button.text}
      </Button>
    )}

    {button.url && (
      <Button type="externalLink" href={button.url} {...props}>
        {button.text}
      </Button>
    )}
  </>
)

LinkButton.propTypes = {
  button: PropTypes.object,
}

export default LinkButton
